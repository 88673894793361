// import AOS from 'aos';
import 'swiper/swiper.min.css';
import 'swiper/swiper-bundle.min.css';
import Swiper, { Navigation, Pagination, Autoplay, EffectFade , Thumbs } from 'swiper';
import AOS from 'aos';

Swiper.use([Navigation, Pagination, Autoplay, EffectFade, Thumbs]);
window.Swiper = Swiper;

// JavaScript
$(document).ready( function () {
  AOS.init();

  // header
  $('.menu-link').click(function(e) {
    $(this).parent().toggleClass('active');
  });
  $('.header-mobile-menu').on('click', function () {
    $(this).toggleClass('active');
    $('#headerMenu').toggleClass('active');
    $('#header').toggleClass('active');
  });

  $(window).on('scroll', function () {
    // transparent header
    if ($(window).scrollTop() > 100) {
      $('.header').addClass("min-header");
    } else {
      $('.header').removeClass("min-header");
    }
  });

  // index slider
  var indexBanner = new Swiper('#indexBanner', {
    effect: 'fade',
    autoplay: {
      delay: 5000,
    },
    speed: 1000,
    loop: true,
    parallax: true,
    pagination: {
      el: ".swiper-pagination",
      clickable: true
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  });

  var indexProduct = new Swiper('#indexProduct', {
    grabCursor: true,
    slidesPerView: 1.3,
    spaceBetween: 10,
    loop: true,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    breakpoints: {
      800: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      900: {
        slidesPerView: 4,
        spaceBetween: 28,
      },
      2000: {
        slidesPerView: 5,
        spaceBetween: 35,
      },
    },
  });

  //scroll top
  $('#backTop').click(function(){
    $("html,body").animate({scrollTop: 0}, 600);
  });

});
